import React from 'react'
import './Learn.css'
import { useEffect } from 'react'
import { FaHandshake, FaIcons, FaMedal, FaTrophy } from 'react-icons/fa'
import { FaLightbulb, FaStar } from 'react-icons/fa6'
import { useInView } from 'react-intersection-observer'

export default function Learn() {

    const [ref, InView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    })
    const handleEffect = () => {
        const icons = document.querySelectorAll('.learn-analytics');
        const im = document.querySelector('.im-learn')
        const im1 = document.querySelector('.im-learn1')
        if (im && im1) {
            im.style.opacity = '1';
            im.style.transform = 'translateX(180px)'
            im1.style.opacity = '1';
            im1.style.transform = 'translateY(-70px)'
        }
        icons.forEach(icon => {
            icon.style.opacity = '1';
            icon.style.transitionDelay = '1.5s';
            icon.style.transform = 'translateX(135px)';
        });
    }

    useEffect(() => {
        if (InView) {
            setTimeout(() => {
                handleEffect();
            }, 1000);
        }
    }, [InView]);

    return (
        <div className='learn-container' ref={ref}  id="achievement">
            <div className='learn-bottom'>
                <div className='learn-left'>
                    <img className='im-learn' src='learn.png' />
                    <img className='im-learn1' src='learn1.png' />
                </div>
                <div className='learn-next'>
                    <p className='inspire-text'>
                        About Course
                    </p>
                    <hr className='inspire-line'></hr>
                    <h2 className='head-1learn'>
                        What You'll <span className='bold'> Learn </span> 
                    </h2>
                    <p className='para-learn'>
                        <ul>
                            <li>
                                The core principles of NLP and how they apply to personal and professional coaching.
                            </li>
                            <li>
                                How to use NLP techniques to help clients overcome mental blocks, limiting beliefs, and unproductive behavior patterns.
                            </li>
                            <li>
                                Powerful communication strategies that enhance your ability to connect with clients, understand their needs, and guide them toward breakthroughs.
                            </li>
                            <li>
                                How to apply Hypnosis and NLP techniques in real-world coaching scenarios to create fast, effective, and lasting transformations.
                            </li>
                            <li>
                                The science of how thoughts, language, and behavior interact—and how to leverage this understanding to help clients achieve their goals.
                            </li>
                            <li>
                                Practical exercises and demonstrations that will build your confidence in using Hypnosis and NLP techniques immediately in your coaching practice
                            </li>
                        </ul>
                    </p>
                    <div className='learn-analytics'>
                        <div className='learn-analytics-inner'>
                            <FaLightbulb className='learn-icon learn-icon1' />
                            <p className='learn-text'>
                                Innovation
                            </p>
                            <hr className='learn-line'>
                            </hr>
                        </div>
                        <div className='learn-analytics-inner'>
                            <FaStar className='learn-icon learn-icon2' />
                            <p className='learn-text'>
                                Excellence
                            </p>
                            <hr className='learn-line'>
                            </hr>
                        </div>
                        <div className='learn-analytics-inner'>
                            <FaHandshake className='learn-icon learn-icon3' />
                            <p className='learn-text'>
                                Professionalism
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
