import React, { useEffect } from 'react'
import './Contact.css';
import { FaInstagram, FaLinkedin, FaFacebook, FaWhatsapp, FaBars, FaTimes, FaPhoneAlt, FaEnvelope, FaComments } from 'react-icons/fa';
import { FaTwitter, } from 'react-icons/fa6';
import { useInView } from 'react-intersection-observer';


export default function
    
    () {
    const [ref, InView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    }); 
    const handleEffect = () =>{
        const element1 =  document.querySelector('.rect1');
        if(element1){
            element1.style.opacity = '1'
            element1.style.transform = 'translateY(-50px)'
        }
    }
    const handleEffect1 = () =>{
        const element1 =  document.querySelector('.rect2');
        if(element1){
            element1.style.opacity = '1'
            element1.style.transform = 'translateY(-50px)'
        }
    }
    const handleEffect2 = () =>{
        const element1 =  document.querySelector('.rect3');
        if(element1){
            element1.style.opacity = '1'
            element1.style.transform = 'translateY(-50px)'
        }
    }
    useEffect(() => {
        if (InView) {
            setTimeout(() => {
                handleEffect();
            }, 100);
            setTimeout(() => {
                handleEffect1();
            }, 400);
            setTimeout(() => {
                handleEffect2();
            }, 800);
        }
    }, [InView]);

    return (
        <div className='Contact' ref={ref}>
            <div className='contact-main'>
                <div className='contact-header'>
                    <p className='header-text'>
                        ITL PROVIDES WITH QUALITY SERVICES
                    </p>
                    <p className='header-sub-text'>
                        Limited Seats Only
                    </p>
                </div>
                <div className='contact-back'>
                    <h1 className='about'>
                        Contact Us
                    </h1>
                    <p className='about-title'>
                        Driven by passion and excellence.
                    </p>
                    <div className='contact-rect-outer'>
                        <div className='contact-rect-inner rect1'>
                            <FaComments size={45} className='about-icons rect01' />
                            Chat
                        </div>
                        <div className='contact-rect-inner rect2'>
                            <FaPhoneAlt size={45} className='about-icons rect02' />
                            Phone
                        </div>
                        <div className='contact-rect-inner rect3'>
                            <FaEnvelope size={45} className='about-icons rect03' />
                            Mail
                        </div>
                    </div>
                </div>
                <div className='contact-icons'>
                    <div className='contact-icon-1'>
                        <div className='icon-circle'>
                            <a href='https://www.facebook.com/profile.php?id=61565733950859&mibextid=ZbWKwL'>
                                <FaFacebook className='contact-icon-name' size={30} />
                            </a>
                        </div>
                    </div>
                    <div className='contact-icon-1'>
                        <div className='icon-circle'>
                        <a href='https://www.instagram.com/invites/contact/?igsh=s84bfl308tz&utm_content=vvdsn1k'>
                                <FaInstagram className='contact-icon-name' size={30} />
                            </a>
                        </div>
                    </div>
                    <div className='contact-icon-1'>
                        <div className='icon-circle'>
                        <a href='https://www.linkedin.com/company/104668233'>
                                <FaLinkedin className='contact-icon-name' size={30} />
                            </a>
                        </div>
                    </div>
                    <div className='contact-icon-1'>
                        <div className='icon-circle'>
                        <a href='https://www.facebook.com/profile.php?id=61565733950859&mibextid=ZbWKwL'>
                                <FaTwitter className='contact-icon-name' size={30} />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
