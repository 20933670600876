import React, { useEffect, useState } from 'react';
import { FaBars, FaUserPlus, FaEnvelope, FaFacebook, FaInstagram, FaLinkedin, FaPhoneAlt, FaTimes, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
import './NavbarBottom.css';

const Navbar = () => {


  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleMobileMenu = () => setIsOpen(!isOpen);
  const toggleDropdownMenu = () => setIsDropdownOpen(!isDropdownOpen);

  const [ref, InView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  const handleIcons = () => {
    const elements = document.querySelectorAll('.social-icons-2');
    elements.forEach(el => {
      el.style.transform = 'translateY(-8px)';
    });
  };
  const handleIcons1 = () => {
    const elements = document.querySelectorAll('.social-icons-2');
    elements.forEach(el => {
      el.style.transform = 'translateY(5px)';
    });
  };

  useEffect(() => {
    let intervalId;
    if (InView) {
      intervalId = setInterval(() => {
        handleIcons();
        setTimeout(() => {
          handleIcons1();
        }, 500);
      }, 2000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [InView]);


  return (
    <nav>
      <div className="navbar" ref={ref}>
        <div>
          <button className="nav-CTA">
            <FaWhatsapp className='contact-button-icons' style={{ marginRight: '4px' }} />
            Whatsapp
          </button>
          <button className="nav-CTA">
            <FaUserPlus className='contact-button-icons' style={{ marginRight: '8px' }} />
            Enroll
          </button>
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isOpen ? <FaTimes size={24} color="white" /> : <FaBars size={24} color="black" />}
        </div>
        <ul className={isOpen ? 'nav-links-mobile' : 'nav-links'}>
          <li><Link className="nav-buttons" to="/home">Home</Link></li>
          <li><Link className="nav-buttons" to="/contact">Contact</Link></li>
          <li className="dropdown">
            <Link className="nav-buttons" to="/courses" onClick={toggleDropdownMenu}>
              Courses
            </Link>
            {isDropdownOpen && (
              <ul className="dropdown-content">
                <li><Link className="dropdown-item" to="/leadership">Leadership</Link></li>
                <li><Link className="dropdown-item" to="/sales">Sales</Link></li>
                <li><Link className="dropdown-item" to="/technology">Technology</Link></li>
                <li><Link className="dropdown-item" to="/coaching">Coaching</Link></li>
              </ul>
            )}
          </li>
        </ul>
        <div className="contact-info">
        <a href="https://www.linkedin.com/company/104668233" target="_blank" rel="noopener noreferrer" className="social-icon-link">
        <FaLinkedin className='social-icons-2 social-1' color='#0c0c0c'/>
      </a>
      <a href="https://www.facebook.com/profile.php?id=61565733950859&mibextid=ZbWKwL" target="_blank" rel="noopener noreferrer" className="social-icon-link">
        <FaFacebook className='social-icons-2 social-2' color='#0c0c0c' />
      </a>
      <a href="https://www.instagram.com/invites/contact/?igsh=s84bfl308tz&utm_content=vvdsn1k" target="_blank" rel="noopener noreferrer" className="social-icon-link">
        <FaInstagram className='social-icons-2 social-3' color='#0c0c0c'/>
      </a>
      <a href="https://x.com/WeAreITL" target="_blank" rel="noopener noreferrer" className="social-icon-link">
        <FaTwitter className='social-icons-2 social-4' color='#0c0c0c'/>
      </a>
        </div>
      </div>
      <div className="navbar-bottom" >
        <img className="im-lo" src="lo.png" alt="Logo" />
        <div className="nav-bottom-area">
          <p className="nav-para">Time Line Therapy®</p>
          <p className="nav-para">The Secret of Creating Your Future®</p>
          <p className="nav-para">Creating Your Future®</p>
          <p className="nav-para-2">
            are registered trademarks licensed exclusively
            to the Time Line Therapy® Association
          </p>
        </div>
        <div className="nav-bottom-area area-1">
          <div className="navbar-contact">
            <div className="navbar-contact-left">
              <FaEnvelope className="navbar-email-icon" />
            </div>
            <div className="navbar-contact-next">
              <p className="nav-para">Email</p>
              <p className="nav-para-1">Info@instituteofthoughtleaders.com</p>
              <p className="nav-para">Address</p>
              <p className="nav-para-1">22, PCSIR Staff Colony, Johar Town, Lahore, Pakistan</p>
            </div>
          </div>
        </div>
        <div className="nav-bottom-area area-2" >
          <div className="navbar-contact">
            <div className="navbar-contact-left">
              <FaPhoneAlt className="navbar-phone-icon" />
            </div>
            <div className="navbar-contact-next">
              <p className="nav-para">Phone-1</p>
              <p className="nav-para-1">+923214205898</p>
              <p className="nav-para">Phone-2</p>
              <p className="nav-para-1">(+92)045678898</p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
