import React from 'react';
import './Price.css';

export default function Price() {
    return (
        <div className='price-container'  id="pricing">
            <h1 className='head-price'>
                Course <span className='bold'>Pricing</span>
            </h1>
            <p className='para-price'>
                Certified Neuro-Linguistic Programming and Hypnosis Practitioner
            </p>

            <div className='price-cards'>

                <div className='price-card'>
                    <h2>Pay in Full</h2>
                    <div className='price'>$1959</div>
                    <div className='discount'>
                        Early Bird Discount! (Ends 10 October 2024)
                    </div>
                    <ul>
                        <li>Certified NLP practitioner training</li>
                        <li>Master powerful techniques</li>
                        <li>Flexible payment plans available</li>
                        <li>Grow your client base and business</li>
                    </ul>
                    <button>Enroll Now</button>
                </div>
                <div className='price-card'>
                    <h2>Get Started with </h2>
                    <div className='price'>$260</div>
                    <p className='installments'>
                        Equal monthly installments over 12 months.
                    </p>
                    <ul>
                        <li>Certified NLP practitioner training</li>
                        <li>Master powerful techniques</li>
                        <li>Flexible payment plans available</li>
                        <li>Grow your client base and business</li>
                    </ul>
                    <button>Get Started</button>
                </div>

            </div>
        </div>
    );
}
