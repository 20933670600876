import React from 'react'
import Courses from '../components/Courses'
import Analytics from '../components/Analytics'

export default function 
() {
  return (
    <div>
        <Courses/>
        <Analytics/>
    </div>
  )
}
