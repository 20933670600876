import React from 'react'
import './NavbarBottom.css'

export default function () {
  const scrollToSection = (id) => {
    const el = document.querySelectorAll('.nav-home-buttons')
    el.forEach(button => {
      button.style.border = 'none';
      button.style.outline = 'none';
    })
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <div>
      <div className="home-nav">
        <button className="nav-home-buttons" onClick={() => scrollToSection('about')}>
          About
        </button>
        <button className="nav-home-buttons" onClick={() => scrollToSection('achievement')}>
          Achievement
        </button>
        <button className="nav-home-buttons" onClick={() => scrollToSection('bonus')}>
          Bonus
        </button>
        <button className="nav-home-buttons" onClick={() => scrollToSection('disclaimer')}>
          Disclaimer
        </button>
        <button className="nav-home-buttons" onClick={() => scrollToSection('pricing')}>
          Pricing
        </button>
        <button className="nav-home-buttons" onClick={() => scrollToSection('testimonials')}>
          Testimonials
        </button>
        <button className="nav-home-buttons" onClick={() => scrollToSection('contact-form')}>
          Enroll
        </button>
        <button className="nav-home-buttons" onClick={() => scrollToSection('questions')}>
          FAQ
        </button>
      </div>
    </div>
  )
}
