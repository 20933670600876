import React from 'react'
import './Courses.css'
import { useNavigate } from 'react-router-dom'

export default function
    () {
        const navigator = useNavigate();
    return (
        <div className='all-courses'>
            <div className='contact-header'>
                <p className='header-text'>
                    ITL PROVIDES WITH QUALITY SERVICES
                </p>
                <p className='header-sub-text'>
                    Limited Seats Only
                </p>
            </div>
            <div className='course-back'>
                <h1 className='course-title'>
                    ALL COURSES
                </h1>
                <p className='course-sub-title'>
                    Explore Our Comprehensive Training Courses
                </p>
            </div>
            <div className='course-introduction'>
                <h1 className='course-title-1'>
                    Introduction to Our Courses
                </h1>
                <p className='course-para'>
                    Welcome to our training courses page, where excellence meets opportunity. Our diverse range of courses is designed to cater to professionals across various industries, helping them enhance their skills, advance their careers, and achieve their goals. Whether you’re looking to strengthen your leadership abilities, master sales techniques, delve into technology training, or benefit from personalized coaching, we have the right course for you.
                </p>
            </div>
            <div className='my-courses'>
                <div className='course-1'>
                    <div className='course-1left'>

                    </div>
                    <div className='course-1next'>
                        <div className='course-next-inner'>
                            <h2 className='course-title-1'>
                                Leadership Training
                            </h2>
                            <p className='course-sub-title'>
                                Duration. 6 weeks
                            </p>
                            <p className='course-date'>
                                2nd October, 2025
                            </p>
                            <p className='course-location'>
                                Lahore, Punjab
                            </p>
                            <p className='course-disp'>
                                Our Leadership Training Courses are crafted to transform potential into performance. Gain insights into effective leadership styles, strategic thinking, and team management. These courses are ideal for current and aspiring leaders looking to drive impactful change within their organizations.
                            </p>
                            <button className='course-readmore' onClick={()=>{
                                navigator('/leadership');
                            }}>
                                Read more
                            </button>
                        </div>
                    </div>
                </div>
                <hr className='course-line'>
                </hr>
            </div>
            <div className='my-courses'>
                <div className='course-1'>
                    <div className='course-1left course-2'>

                    </div>
                    <div className='course-1next'>
                        <div className='course-next-inner'>
                            <h2 className='course-title-1'>
                                Sales Mastery
                            </h2>
                            <p className='course-sub-title'>
                                Duration. 6 months
                            </p>
                            <p className='course-date'>
                                2nd October, 2025
                            </p>
                            <p className='course-location'>
                                Lahore, Punjab
                            </p>
                            <p className='course-disp'>
                                Elevate your sales skills with our Sales Mastery Courses. Learn advanced techniques in negotiation, client management, and sales strategy to boost your performance and achieve your sales targets. Perfect for sales professionals aiming to refine their skills and excel in competitive markets.
                            </p>
                            <button className='course-readmore' onClick={()=>{
                                navigator('/leadership');
                            }}>
                                Read more
                            </button>
                        </div>
                    </div>
                </div>
                <hr className='course-line'>
                </hr>
            </div>
            <div className='my-courses'>
                <div className='course-1'>
                    <div className='course-1left course-3'>

                    </div>
                    <div className='course-1next'>
                        <div className='course-next-inner'>
                            <h2 className='course-title-1'>
                                Technology Training
                            </h2>
                            <p className='course-sub-title'>
                                Duration. 6 months
                            </p>
                            <p className='course-date'>
                                2nd October, 2025
                            </p>
                            <p className='course-location'>
                                Lahore, Punjab
                            </p>
                            <p className='course-disp'>
                                Stay ahead in the digital landscape with our Technology Training courses. Focused on cybersecurity and other critical tech areas, these courses equip you with the knowledge needed to safeguard your organization’s digital assets and understand the latest technology trends.
                            </p>
                            <button className='course-readmore' onClick={()=>{
                                navigator('/leadership');
                            }}>
                                Read more
                            </button>
                        </div>
                    </div>
                </div>
                <hr className='course-line'>
                </hr>
            </div>
            <div className='my-courses'>
                <div className='course-1'>
                    <div className='course-1left course-4'>

                    </div>
                    <div className='course-1next'>
                        <div className='course-next-inner'>
                            <h2 className='course-title-1'>
                                Executive Coaching
                            </h2>
                            <p className='course-sub-title'>
                                Duration. 6 months
                            </p>
                            <p className='course-date'>
                                2nd October, 2025
                            </p>
                            <p className='course-location'>
                                Lahore, Punjab
                            </p>
                            <p className='course-disp'>
                                Experience personalized growth with our Executive Coaching Services. Our coaching programs are designed to address your unique needs, providing support and guidance to help you achieve your professional and personal goals. Whether you're seeking to improve your leadership skills or tackle specific challenges, our coaching services offer tailored solutions for your success.
                            </p>
                            <button className='course-readmore' onClick={()=>{
                                navigator('/leadership');
                            }}>
                                Read more
                            </button>
                        </div>
                    </div>
                </div>
                <hr className='course-line'>
                </hr>
            </div>
        </div>
    )
}
