import React from 'react'
import './Blog.css';

export default function 
() {
  return (
    <div className='blog-container'>
        <h1>
          Blogs and Posts
        </h1>
    </div>
  )
}
