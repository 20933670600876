import React from 'react';
import './ContactForm.css';
import { FaEnvelopeOpenText } from 'react-icons/fa';

export default function ContactForm() {

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    const form = e.target;
    const formData = new FormData(form);

    const response = await fetch('https://formspree.io/f/mrbzdoej', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json'
      }
    });

    if (response.ok) {
      console.log('Form submitted successfully');
      form.reset();
      alert('Thank you! Your message has been sent.');
    } else {
      console.log('Form submission error');
      alert('Oops! Something went wrong.');
    }
  };

  return (
    <div className='contact-form1' id="contact-form">
      <h3 className='head'>
        Lead with Impact – Begin Your Journey Today!
      </h3>
      <h1 className='bold'>
        Contact Now
      </h1>
      <div className='contact-container1'>
        <div className='contact-container-next'>
          <form onSubmit={handleSubmit} className='my-form'>
            <div className="input-box">
              <input type="text" name="name" required style={{ color: 'white', backgroundColor: '#0c0c0c' }} />
              <label>Name</label>
            </div>
            <div className="input-box">
              <input type="email" name="email" required style={{ color: 'white', backgroundColor: '#0c0c0c' }} />
              <label>Email</label>
            </div>
            <div className="input-box">
              <input name="number" required style={{ color: 'white', backgroundColor: '#0c0c0c' }} />
              <label>Number</label>
            </div>
            <div className="input-box">
              <textarea name="message" required style={{ color: 'white', backgroundColor: '#0c0c0c' }}></textarea>
              <label>Message</label>
            </div>
            <button  type="submit" className="submit-btn submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}
