import React from 'react'
import Blog from '../components/Blog';

export default function 
() {
  return (
    <div>
        <Blog/>
    </div>
  )
}
