import React from 'react'
import './Inspire.css'
import { FaAddressBook, FaBold, FaCodeBranch, FaIcons, FaReadme, FaRocket, FaSpeakap, FaSpeakerDeck } from 'react-icons/fa'

function Inspire() {

    return (
        <div className='inspire-container' id="about">
            <p className='inspire-text'>
                Inspire
            </p>
            <hr className='inspire-line'></hr>
            <div className='inspire-up'>
                <h1 className='inspire-title'>
                    Who This <span className='inspire-span-text'> Course </span> Is For
                </h1>
                <p className='inspire-title-para'>
                    The Certified Neuro-Linguistic Programming and Hypnosis Practitioner course is perfect for:
                </p>
            </div>
            <div className='inspire-bottom'>
                <div className='inspire-left'>
                    <div className='inspire-left-boxes'>
                        <div className='inspire-icon-box'>
                            <div>
                                <FaAddressBook className='box-icons' size={30} />
                            </div>
                        </div>
                        <div className='inspire-box-content'>
                            <h3 className='box-title'>
                                Aspiring Coaches
                            </h3>
                            <p className='box-para'>
                                who want to build a strong foundation for their coaching practice and learn the core principles of NLP to deliver powerful results for their clients.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className='inspire-left-boxes'>
                            <div className='inspire-icon-box'>
                                <div>
                                    <FaRocket className='box-icons' size={30} />
                                </div>
                            </div>
                            <div className='inspire-box-content'>
                                <h3 className='box-title'>
                                    Existing Coaches
                                </h3>
                                <p className='box-para'>
                                    looking to add NLP techniques to their toolkit and stand out in a crowded coaching industry.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className='inspire-left-boxes'>
                            <div className='inspire-icon-box'>
                                <div>
                                    <FaReadme className='box-icons' size={30} />
                                </div>
                            </div>
                            <div className='inspire-box-content'>
                                <h3 className='box-title'>
                                    Coaches Seeking Confidence
                                </h3>
                                <p className='box-para'>
                                    If you've ever felt unsure about how to handle certain clients or situations, this course will give you the structure and strategies to coach with certainty and confidence.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='inspire-next'>
                    <div className='inspire-next-inner'>
                        <p className='box-inner-para'>
                            Therapists or Consultants
                        </p>
                        <h5>
                            with guest Dr. Adriana James
                        </h5>
                        <hr className='my-line-inspire'>
                        </hr>
                        <p>
                            If you're ready to move beyond generic coaching techniques and take your practice to the next level with NLP, this course is for you. However, if you are not interested in leading a life filled with purpose and financial success then you can close this page now. This work is not for you.
                        </p>
                        <button className='inspire-button'>
                            Learn More
                        </button>
                    </div>
                </div >
            </div>
        </div>
    )
}

export default Inspire