import React, { useState } from 'react';
import { FaBookOpen, FaTimes, FaChalkboardTeacher, FaLaptopCode, FaHandshake, FaRegLightbulb } from 'react-icons/fa';
import './CoursesPopup.css';

const CoursesPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="courses-icon" onClick={togglePopup}>
        <FaBookOpen size={30} color="#000" />
      </div>
      <div className={`courses-popup ${isOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={togglePopup}>
          <FaTimes className='icon-services-2' size={20} />
        </button>
        <h5 className='head-popup'>Our Courses</h5>
        <div className="courses-list">
          <div className="course-item">
            <button className='popup-menu'>
              <FaChalkboardTeacher size={25} className="course-icon" />
              Leadership
            </button>
          </div>
          <div className="course-item">
            <button className='popup-menu'>
              <FaLaptopCode size={25} className="course-icon" />
              Technology
            </button>
          </div>
          <div className="course-item">
            <button className='popup-menu'>
              <FaHandshake size={25} className="course-icon" />
              Sales Mastery
            </button>
          </div>
          <div className="course-item">
            <button className='popup-menu'>
              <FaRegLightbulb size={25} className="course-icon" />
              Coaching
            </button>
          </div>
        </div>
        <hr className='popup-line'></hr>
        <button className='popup-view-button'>
          View Courses
        </button>
      </div>
    </>
  );
};

export default CoursesPopup;
