import React from 'react'
import Leadership from '../components/Leadership'

export default function 
() {
  return (
    <div>
        <Leadership/>
    </div>
  )
}
