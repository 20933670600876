import React, { useState } from 'react'
import './Maps.css'
import { FaEnvelope, FaIcons, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhoneAlt, FaVoicemail } from 'react-icons/fa'
import { FaClipboard, FaLocationPin, FaLocationPinLock } from 'react-icons/fa6'

export default function
    () {
    const [Value, setValue] = useState('Copy');
    const handleCopy = () => {
        setValue('Copied');
        setTimeout(() => {
            setValue('Copy');
        },1000);
    }
    const handleCopy1 = () => {
        setTimeout(() => {
            setValue('Copy');
        }, 500);

    }
    return (
        <div>
            <div className='maps-container'>
                <div className='maps-left'>
                    <h2 className='about-head-01'>
                        Your Trusted Partner in <span className='about-head-1'>Professional Growth </span>  and Development
                    </h2>
                    <p className='about-para'>
                        At ITL, and organizations across the UAE with the skills and knowledge needed to excel in today's competitive landscape. With our expert-led training programs, comprehensive consulting services, and tailored coaching solutions, we help leaders and teams unlock their full potential. Whether you seek to enhance leadership capabilities, boost sales performance, or secure your digital assets, our commitment is to drive your success with innovative and effective strategies.
                    </p>
                    <p className='about-para'>
                        At ITL, <span className='about-head-1'>we are delighted to empower the individuals </span> and organizations across the UAE with the skills and knowledge needed to excel in today's competitive landscape. With our expert-led training programs, comprehensive consulting services, and tailored coaching solutions, we help leaders and teams unlock their full potential. Whether you seek to enhance leadership capabilities, boost sales performance, or secure your digital assets, our commitment is to drive your success with innovative and effective strategies.
                    </p>
                </div>
                <div className='maps-next'>
                    <iframe className='map-location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6808.854162988131!2d74.28148869007731!3d31.42990740174224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190118f19c097d%3A0x8bd69b4aa35457f3!2sPCSIR%20Staff%20Colony%2C%20Lahore%2C%20Punjab%2C%20Pakistan!5e0!3m2!1sen!2s!4v1725606505746!5m2!1sen!2s"
                        referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <div className='more-links'>
                <div className='social-rect rect1' onClick={handleCopy} onMouseLeave={handleCopy1}>
                    <FaPhoneAlt className='rect01' color='#1a1a1a' size={40} />
                    <div className='copy-info copy-1'>
                        <div>
                            <FaClipboard className='copy-icon' color='black' size={14} />
                        </div>
                        <div>
                            <p className='copy-text'>
                                {Value}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='social-rect rect2' onClick={handleCopy} onMouseLeave={handleCopy1}>
                    <FaEnvelope className='rect02' color='#1a1a1a' size={40} />
                    <div className='copy-info copy-2'>
                        <div>
                            <FaClipboard className='copy-icon' color='black' size={14} />
                        </div>
                        <div>
                            <p className='copy-text'>
                                {Value}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='social-rect rect3' onClick={handleCopy} onMouseLeave={handleCopy1}>
                    <FaLocationPin className='rect03' color='#1a1a1a' size={40} />
                    <div className='copy-info copy-3'>
                        <div>
                            <FaClipboard className='copy-icon' color='black' size={14} />
                        </div>
                        <div>
                            <p className='copy-text'>
                                {Value}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
