import React from 'react';
import Contact from '../components/Contact';   
import Maps from '../components/Maps';         
import ContactForm1 from '../components/ContactForm1';
import LastContact from '../components/LastContact'

export default function ContactPage() {
  return (
    <div className='Contact-page'>
      <Contact />
      <Maps />
      <LastContact />
    </div>
  );
}
