import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContactPage from './WebPages/ContactPage';
import AboutPage from './WebPages/AboutPage'
import Home from './WebPages/Home'
import BlogPage from './WebPages/BlogPage';
import LeadershipPage from './WebPages/LeadershipPage';
import CoursesPage from './WebPages/CoursesPage';
import ScrollToTop from './components/ScrollToTop';

function App() {
  return (
    <>
      <Router>
        <link
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css "
          rel="stylesheet"
        />
        <script
          src="https://code.jquery.com/jquery-3.3.1.slim.min.js"
          integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js"
          integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGaXMOvQ2K7jaPz0XKp5vvo5MR"
          crossOrigin="anonymous"
        ></script>
        <script
          src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js"
          integrity="sha384-ChfqqxuZUCnJSK3+MXmPNIyE6ZbWh2IMqE241rYiqJxyMiZ6OW/JmZQ5stwEULTy"
          crossOrigin="anonymous"
        ></script>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />}/>
          <Route path="/about" element={<AboutPage />}/>
          <Route path="/contact" element={<ContactPage />}/>
          <Route path="/post" element={<BlogPage />}/>
          <Route path="/courses" element={<CoursesPage />}/>
          <Route path="/leadership" element={<LeadershipPage />}/>
        </Routes>
        <Footer />
      </Router>

    </>
  );
}

export default App;
