import React from 'react'
import Programs from '../components/Programs';
import Slider from '../components/Slider';
import SkillsDisplay from '../components/SkillsDisplay';
import Services from '../components/Services';
import ContactForm1 from '../components/ContactForm1'
import Popup from '../components/Popup'
import Inspire from '../components/Inspire';
import Offer from '../components/Offer';
import Business from '../components/Business';
import Disclamer from '../components/Disclamer';
import Learn from '../components/Learn'
import Price from '../components/Price';
import ScrollToTopButton from '../components/ScrollToTopButton'
import CoursesPopup from '../components/CoursesPopup'
import Bonous from '../components/Bonous'
import Questions from '../components/Questions'
import NavHome from '../components/HomeNav'
import Enroll from '../components/Enroll'
import Athourity from '../components/Athourity'
import Testinomial from '../components/Testinomial';
import LastContact from '../components/LastContact'

function Home() {
    return (
        <div className='Home'>
            <NavHome />
            <Slider />
            <Business />
            <Enroll />
            <Popup />
            <ScrollToTopButton />
            <CoursesPopup />
            <Inspire />
            <Disclamer />
            <Learn />
            <Bonous />
            <Price />
            <ContactForm1 />
            <Questions />
            <Athourity/>
            <LastContact/>
        </div>
    )
}

export default Home