import React, { useState, useEffect } from 'react';
import { FaInstagram, FaLinkedin, FaFacebook, FaWhatsapp, FaBars, FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

export default function Slider() {
  const navigate = useNavigate()
  const texts = [
    "Ambitious Coaches",
    "Therapists",
    "Counsellors",
  ];

  const TypingEffect = () => {
    const [currentText, setCurrentText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [loopNum, setLoopNum] = useState(0);
    const [typingSpeed, setTypingSpeed] = useState(150);

    useEffect(() => {
      const handleTyping = () => {
        const fullText = texts[loopNum % texts.length];
        setCurrentText(
          isDeleting
            ? fullText.substring(0, currentText.length - 1)
            : fullText.substring(0, currentText.length + 1)
        );

        setTypingSpeed(isDeleting ? 30 : 160);

        if (!isDeleting && currentText === fullText) {
          setTimeout(() => setIsDeleting(true), 500);
        } else if (isDeleting && currentText === "") {
          setIsDeleting(false);
          setLoopNum(loopNum + 1);
        }
      };

      const timer = setTimeout(handleTyping, typingSpeed);

      return () => clearTimeout(timer);
    }, [currentText, isDeleting, loopNum, typingSpeed]);

    return (
      <div className="WebDeveloperText">
        <div className='typye-effect'>
          {currentText}
          <span className="cursor">|</span>
        </div>

      </div>
    );
  };

  const [currentSlide, setCurrentSlide] = useState(1);
  const totalSlides = 3;

  const goToPrevSlide = () => {
    const newSlide = currentSlide === 0 ? totalSlides - 1 : currentSlide - 1;
    setCurrentSlide(newSlide);
  };

  const goToNextSlide = () => {
    const newSlide = currentSlide === totalSlides - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(newSlide);
  };
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className='SliderBack' id='home'>
      <div id="carouselExampleIndicators" className="carousel slide">
        <ol className="carousel-indicators">
          <li className={currentSlide === 0 ? 'active' : ''} onClick={() => setCurrentSlide(0)}></li>
          <li className={currentSlide === 1 ? 'active' : ''} onClick={() => setCurrentSlide(1)}></li>
          <li className={currentSlide === 2 ? 'active' : ''} onClick={() => setCurrentSlide(2)}></li>
        </ol>
        <div className="carousel-inner">
          <div className={`carousel-item ${currentSlide === 0 ? 'active' : ''} ${currentSlide === 0 ? 'fade-in' : 'fade-out'}`}>
            <div className='Sliderback'>
              <div className='slide-01'>
                <div className='slide-01-left'>
                  <div className='slide-inner'>
                    <p className='home-title'>
                      <TypingEffect
                      />
                    </p>
                    <h1 className='slide-main-head'>
                      Empowering you with <span className='slide-UAE'>Expert Training and Consulting</span>
                    </h1>
                    <p className='slide-01-para'>
                      Get Certified as Neuro-Linguistic Programming and Hypnosis Practitioner
                    </p>
                    <p className='quote'>

                    </p>
                    <div className='slide-buttons'>
                      <button className='slide-CTA' onClick={() => scrollToSection('contact-form')}>
                        Enroll Today
                      </button>
                      <button className='slide-CTA-1' onClick={() => navigate('/contact')}>
                        Contact Now
                      </button>
                    </div>
                    <div className="social-icons-1">
                      <div className='circle-icon-slide'>
                        <a href='https://www.instagram.com/invites/contact/?igsh=s84bfl308tz&utm_content=vvdsn1k' target='_blank' rel='noopener noreferrer'>
                          <FaInstagram className='insta' color='black' size={21} />
                        </a>
                      </div>
                      <div className='circle-icon-slide'>
                        <a href='https://www.linkedin.com/company/104668233' target='_blank' rel='noopener noreferrer'>
                          <FaLinkedin className='linkdin' color='black' size={21} />
                        </a>
                      </div>
                      <div className='circle-icon-slide'>
                        <a href='https://www.facebook.com/profile.php?id=61565733950859&mibextid=ZbWKwL' target='_blank' rel='noopener noreferrer'>
                          <FaFacebook className='fb' color='black' size={21} />
                        </a>
                      </div>
                    </div>

                  </div>
                </div>
                <div className='slider-01-next'>
                  <img className='im-slider' src='banner.png' />
                </div>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${currentSlide === 1 ? 'active' : ''} ${currentSlide === 1 ? 'fade-in' : 'fade-out'}`}>
            <div className='Sliderback1'>
              <div className='Sliderback'>
                <div className='slide-01'>
                  <div className='slide-01-left'>
                    <div className='slide-inner'>
                      <p className='home-title'>
                        <TypingEffect className="typing-text"
                          text="Institute of Thought Leadership"
                          speed={100}
                        />
                      </p>
                      <h1 className='slide-main-head'>
                        Your Story Awaits: The <span className='slide-UAE'> Transformation Begins</span>  Now
                      </h1>
                      <p className='slide-01-para'>
                        Right now, you stand at the crossroads. Will you continue down the familiar path, or will you take the leap into something new, something powerful, something life-changing?
                      </p>
                      <p className='quote'>

                      </p>
                      <div className='slide-buttons'>
                        <button className='slide-CTA' onClick={() => navigate('/courses')}>
                          Enroll Today
                        </button>
                        <button className='slide-CTA-1' onClick={() => navigate('/contact')}>
                          Contact Now
                        </button>
                      </div>
                      <div className="social-icons-1">
                        <div className='circle-icon-slide'>
                          <a href='https://www.instagram.com/invites/contact/?igsh=s84bfl308tz&utm_content=vvdsn1k' target='_blank' rel='noopener noreferrer'>
                            <FaInstagram className='insta' color='black' size={21} />
                          </a>
                        </div>
                        <div className='circle-icon-slide'>
                          <a href='https://www.linkedin.com/company/104668233' target='_blank' rel='noopener noreferrer'>
                            <FaLinkedin className='linkdin' color='black' size={21} />
                          </a>
                        </div>
                        <div className='circle-icon-slide'>
                          <a href='https://www.facebook.com/profile.php?id=61565733950859&mibextid=ZbWKwL' target='_blank' rel='noopener noreferrer'>
                            <FaFacebook className='fb' color='black' size={21} />
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='slider-01-next'>
                    <img className='im-slider' src='banner.png' />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`carousel-item ${currentSlide === 2 ? 'active' : ''} ${currentSlide === 2 ? 'fade-in' : 'fade-out'}`}>
            <div className='Sliderback2'>
              <div className='Sliderback'>
                <div className='slide-01'>
                  <div className='slide-01-left'>
                    <div className='slide-inner'>
                      <p className='home-title'>
                        <TypingEffect className="typing-text"
                          text="Institute of Thought Leadership"
                          speed={100}
                        />
                      </p>
                      <h1 className='slide-main-head'>
                        <span className='slide-UAE'> “Comfor Zone and Growth do not Coexist”</span>  - Ginni Romety
                      </h1>
                      <p className='slide-01-para'>
                        By enrolling in the Certified  NLP and Hypnosis Practitioner course, you are choosing to step into your own power and help others unlock theirs. The tools you’ll gain are not just skills—they are the keys to transformation, the compass to guide you and your clients to greater heights.
                      </p>
                      <p className='quote'>

                      </p>
                      <div className='slide-buttons'>
                        <button className='slide-CTA' onClick={() => navigate('/courses')}>
                          Enroll Today
                        </button>
                        <button className='slide-CTA-1' onClick={() => navigate('/contact')}>
                          Contact Now
                        </button>
                      </div>
                      <div className="social-icons-1">
                        <div className='circle-icon-slide'>
                          <a href='https://www.instagram.com/invites/contact/?igsh=s84bfl308tz&utm_content=vvdsn1k' target='_blank' rel='noopener noreferrer'>
                            <FaInstagram className='insta' color='black' size={21} />
                          </a>
                        </div>
                        <div className='circle-icon-slide'>
                          <a href='https://www.linkedin.com/company/104668233' target='_blank' rel='noopener noreferrer'>
                            <FaLinkedin className='linkdin' color='black' size={21} />
                          </a>
                        </div>
                        <div className='circle-icon-slide'>
                          <a href='https://www.facebook.com/profile.php?id=61565733950859&mibextid=ZbWKwL' target='_blank' rel='noopener noreferrer'>
                            <FaFacebook className='fb' color='black' size={21} />
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className='slider-01-next'>
                    <img className='im-slider' src='banner.png' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a className="carousel-control-prev y-2" href="#carouselExampleIndicators" role="button" onClick={goToPrevSlide}>
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next y-3s" href="#carouselExampleIndicators" role="button" onClick={goToNextSlide}>
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only next"> Next</span>
        </a>
      </div>
    </div >
  );
}
