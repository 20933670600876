import React from 'react'
import './About.css'
import { FaUserFriends, FaLightbulb, FaHandshake } from 'react-icons/fa'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'

export default function
    () {
        const [ref, InView] = useInView({
            threshold: 0.2,
            triggerOnce: true
        }); 
        const handleEffect = () =>{
            const element1 =  document.querySelector('.rect1');
            if(element1){
                element1.style.opacity = '1'
                element1.style.transform = 'translateY(-50px)'
            }
        }
        const handleEffect1 = () =>{
            const element1 =  document.querySelector('.rect2');
            if(element1){
                element1.style.opacity = '1'
                element1.style.transform = 'translateY(-50px)'
            }
        }
        const handleEffect2 = () =>{
            const element1 =  document.querySelector('.rect3');
            if(element1){
                element1.style.opacity = '1'
                element1.style.transform = 'translateY(-50px)'
            }
        }
        useEffect(() => {
            if (InView) {
                setTimeout(() => {
                    handleEffect();
                }, 100);
                setTimeout(() => {
                    handleEffect1();
                }, 400);
                setTimeout(() => {
                    handleEffect2();
                }, 800);
            }
        }, [InView]);
    return (
        <div className='about-container' ref={ref} id='about1'>
            <div className='contact-header'>
                <p className='header-text'>
                    ITL PROVIDES WITH QUALITY SERVICES
                </p>
                <p className='header-sub-text'>
                    Limited Seats Only
                </p>
            </div>
            <div className='about-back'>
                <h1 className='about'>
                    About Us
                </h1>
                <p className='about-title'>
                    Driven by passion and excellence.
                </p>
                <div className='about-rect-outer'>
                    <div className='about-rect-inner rect1'>
                        <FaUserFriends size={45} className='about-icons rect01' />
                        About
                    </div>
                    <div className='about-rect-inner rect2'>
                        <FaLightbulb size={45} className='about-icons rect02' />
                        Approach
                    </div>
                    <div className='about-rect-inner rect3'>
                        <FaHandshake size={45} className='about-icons rect03' />
                        Commitment
                    </div>
                </div>
            </div>
            <div className='about-main'>
                <div className='about-left'>
                    <h2 className='about-left-head'>
                        Who We Are
                    </h2>
                    <p className='about-left-para'>
                        At the <span className='about-head'> Institute of Thought Leadership</span>, we are a team of passionate professionals dedicated to shaping the future of leadership. Our mission is simple: to empower emerging executives, high-potential managers, and seasoned leaders to achieve unparalleled success.
                        <span className='about-head'> By combining Neuro-Linguistic Programming (NLP), neuroscience </span>, and <span className='about-head'> global leadership best practices</span>, we provide innovative, results-driven training and consulting services that are culturally relevant and tailored to the unique needs of the UAE market.
                    </p>
                </div>
                <div className='about-next'>

                </div>
                <div className='about-left'>
                    <h2 className='about-left-head'>
                        Our Approach
                    </h2>
                    <p className='about-left-para'>
                        We believe that leadership is both an art and a science, which is why our programs blend proven leadership strategies with cutting-edge neuroscience and communication techniques. Our hands-on, immersive training sessions go beyond theory, equipping leaders with practical skills they can apply immediately in their organizations. Whether through one-on-one coaching, group workshops, or virtual training, we prioritize measurable outcomes, ensuring that our clients experience real transformation that drives business success
                    </p>
                </div>
                <div className='about-next'>

                </div>
                <div className='about-left'>
                    <h2 className='about-left-head'>
                        Our Commitment
                    </h2>
                    <p className='about-left-para'>
                        At the Institute of Thought Leadership, we are guided by values of excellence, integrity, innovation, and client-centricity. We are deeply committed to helping organizations and individuals grow, thrive, and lead with purpose. By staying ahead of industry trends and continuously refining our programs, we ensure that our clients receive the most relevant and impactful training. We take pride in being a trusted partner to some of the UAE’s leading organizations and look forward to helping you elevate your leadership to the next level.
                    </p>
                </div>
                <div className='about-next'>

                </div>
            </div>
        </div>
    )
}
