import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import './Analytics.css'

const ScrollComponent = () => {

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    useEffect(() => {
        if (inView) {
            setTimeout(() => {
                Handleanalytics();
            }, 500);
        }
    }, [inView]);

    let [Value1, SetValue1] = useState(0);
    let [Value2, SetValue2] = useState(0);
    let [Value3, SetValue3] = useState(0);
    let [Value4, SetValue4] = useState(0);

    function Handleanalytics() {
        setInterval(() => {
            if (Value1 <= 101) {
                Value1 = Value1 + 1;
                SetValue1(Value1);
            }
            if (Value2 <= 121) {
                Value2 = Value2 + 1;
                SetValue2(Value2);
            }
            if (Value3 <= 120) {
                Value3 = Value3 + 1;
                SetValue3(Value3);
            }
            if (Value4 <= 100) {
                Value4 = Value4 + 1;
                SetValue4(Value4);
            }
        }, 30);

    }

    return (
        <div className="AnalyticsContainer" id='AnalyticsContainer' ref={ref}>
            <div className="AnalyticsBottomContainer">
                <div className='BottomUp'>
                    <div className='Analytics'>
                        <div>
                            <h1 className='AnalyticsValue'>{Value1}+</h1>
                        </div>
                        <div className='AnalyticsIconAndText'>
                            <p className='AnalyticsText'>Projects Completed</p>
                        </div>
                    </div>
                    <div className='Analytics'>
                        <div>
                            <h1 className='AnalyticsValue'>{Value2}+</h1>
                        </div>
                        <div className='AnalyticsIconAndText'>
                            <p className='AnalyticsText'>Running Projects</p>
                        </div>
                    </div>
                    <div className='Analytics'>
                        <div>
                            <h1 className='AnalyticsValue'>{Value3}+</h1>
                        </div>
                        <div className='AnalyticsIconAndText'>
                            <p className='AnalyticsText'>Happy Clients</p>

                        </div>
                    </div>
                    <div className='Analytics'>
                        <div>
                            <h1 className='AnalyticsValue'>{Value4}+</h1>
                        </div>
                        <div className='AnalyticsIconAndText'>

                            <p className='AnalyticsText'>Countries Served</p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ScrollComponent;
