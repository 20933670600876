import './Bonus.css';
import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export default function Bonus() {
    const bonusPoints = [
        { title: 'Client Session Templates', text: "Get access to detailed, step-by-step templates for structuring NLP-based coaching sessions. These templates will help you confidently guide your clients through transformation, ensuring that each session is productive, clear, and outcome-focused." },
        { title: 'Exclusive NLP Technique Demonstrations', text: 'Gain in-depth video walkthroughs of key NLP techniques. These demonstrations ensure that you can apply the techniques correctly and effectively with your clients, giving you the confidence to deliver real results from day one.' },
        { title: 'Goal-Setting and Progress Tracking Tools', text: 'Use our customized goal-setting and tracking tools to help your clients set clear objectives and monitor their progress. These resources will make your coaching even more results-driven, giving you and your clients measurable milestones to celebrate.' },
        { title: '12 Months of Business Support', text: 'After completing the course, you’ll receive a full year of ongoing business support. Whether you need advice on client acquisition, pricing strategies, or scaling your practice, our expert mentors will be there to guide you every step of the way.' },
        { title: '5 Sales Scripts to Get Clients and Overcome Objections', text: 'Unlock access to proven sales scripts designed specifically for coaches. These scripts will help you overcome common objections, close deals with ease, and build a thriving coaching practice. Say goodbye to sales anxiety and hello to a full client roster!' },
        { title: '10 Digital Marketing Resources for Growing Your Business', text: 'Get ahead of the competition with these essential digital marketing tools. From social media strategy to email marketing templates, these resources are designed to help you market your coaching services effectively and attract high-paying clients.' },
        { title: '3-Day Krasner Hypnosis Course (Free)', text: 'As a bonus, you’ll receive complimentary access to a 3-day Krasner Hypnosis course, where you’ll learn advanced hypnosis techniques to deepen your skill set and enhance your client transformations.' },
        { title: '50% Off Enrollment in NLP and Hypnosis Master Practitioner', text: 'Ready to take your expertise to the next level? Upon completing this course, you’ll receive 50% off your enrollment in the NLP and Hypnosis Master Practitioner program, allowing you to continue growing your skills and income potential.' },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.1 });

    useEffect(() => {
        if (inView) {
            const interval = setInterval(() => {
                setCurrentIndex(prevIndex => (prevIndex + 1) % bonusPoints.length);
            }, 3000); 

            return () => clearInterval(interval);
        }
    }, [inView]);

    return (
        <div className='bonus-container' ref={ref} id="bonus">
            <h1 className='head'>
                <span className='bold'>Bonuses</span> Included with Your Certification
            </h1>
            <div className='bonus-wrapper'>
                <div className='bonus-content' style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                    {bonusPoints.map((bonus, index) => (
                        <div key={index} className='bonus-item'>
                            <hr className='bonus-line'></hr>
                            <h3>{index + 1}. {bonus.title}</h3>
                            <p>{bonus.text}</p>
                            <hr className='bonus-line'></hr>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
