import React, { useState, useEffect } from 'react';
import './Business.css';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

export default function Business() {
    const cardsData = [
        {
            title: 'Build your own path',
            text: "Build your own path, don't clock in for someone else's dream.",
            image: 'card1.jpg',
        },
        {
            title: 'Stream of Income.',
            text: 'Every day is filled with purpose, fulfillment, and a steady stream of income.',
            image: 'card2.jpg',
        },
        {
            title: 'Existing Practice',
            text: 'Command higher fees in your existing practice.',
            image: 'card3.jpg',
        },
        {
            title: 'Life-Changing Results',
            text: 'Unlock your own potential and achieve life-changing results.',
            image: 'card4.jpg',
        },
        {
            title: '12 Months Support',
            text: 'Receive 12 months support on setting up your business.',
            image: 'card5.jpg',
        },
        {
            title: 'American Board of NLP ',
            text: 'Join American Board of NLP and Hypnotherapy as Practitioner',
            image: 'card6.jpg',
        },
        {
            title: 'Hypnotherapists',
            text: 'Pathway to join Australian Hypnotherapists Association',
            image: 'card7.jpg',
        },
    ];

    const [currentIndex, setCurrentIndex] = useState(0);
    const cardsToShow = 3;

    const nextSlide = () => {
        if (currentIndex + cardsToShow < cardsData.length) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };
    const [ref, InView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    })
    const handleeffect = () => {
        const element = document.querySelector('.business-container');
        if (element) {
            const el = document.querySelector('.business-bottom');
            if (el) {
                el.style.transform = 'translateY(-20px)';
                el.style.opacity = '1'
            }
        }
    }
    useEffect((InView) => {
        if (InView) {
            setTimeout(() => {
                handleeffect();
            }, 200);
        }
    }, [InView])


    return (
        <div className='business-container' ref={ref}>
            <h1 className='head'>
                Grow Your Business with <span className='bold'>Gold Standard</span> Certification
            </h1>
            <p className='para'>
                Imagine a world where you don’t just work for a paycheck, but where your daily efforts lead to
                <span className='bold-1'> profound change</span> in the lives of others—and as a result, your own
                <span className='bold-1'> financial success.</span>
            </p>
            <div>
                <div className='business-bottom'>
                    {cardsData.slice(currentIndex, currentIndex + cardsToShow).map((card, index) => (

                        <div className={`card-${index + 1}`} key={index} style={{ backgroundImage: `url(${card.image})`, backgroundSize: 'cover' }}>
                            <div className='card-top'></div>
                            <div className='card-bottom'>
                                <h4 className='card-head'>{card.title}</h4>
                                <p className='card-text'>{card.text}</p>
                                <button className='pro-readmore'>More</button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='business-slider'>
                <div onClick={prevSlide} >
                    <FaArrowAltCircleLeft className='left-Icon' />
                </div>
                <div onClick={nextSlide} >
                    <FaArrowAltCircleRight className='next-Icon' />
                </div>
            </div>
            <div className='business-quotes'>
                <p>
                    This isn’t a dream. It’s the life you will create as a Certified NLP and Hypnosis Practitioner with us.
                </p>
                <p className='quote'>
                    “The Road to Financial Success Starts with Purpose” - Unknown
                </p>
            </div>
        </div>
    );
}
