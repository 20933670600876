import React from 'react'
import './Disclamer.css'
import { FaIcons, FaMagento, FaSolid } from 'react-icons/fa'
import { FaBrain, FaLightbulb, FaSleeping, FaPath, FaTools, FaSyncAlt, FaExchangeAlt, FaStar, FaTrophy } from 'react-icons/fa';

export default function Disclamer() {
    return (
        <div className='disclamer-container'  id="disclaimer">
            <h3 className='head head-disp'>
                A Quick <span className='bold'> Disclaimer</span>
            </h3>
            <p className='para-dis'>
                This program doesn’t give you unreal promises of success or a flood of new clients. <span className='bold-1'>However, it does: </span>
            </p>
            <div className='disclamer-cards'>
                <div className='card-01'>
                    <div className='disp-circle'>
                    <FaBrain className='dis-icon' />
                    </div>
                    
                    <h4 className='card-head-dis'>
                        Practitioner
                    </h4>
                    <p className='para-disp-inner'>
                        Allow you to join American Board of Neuro Linguistic Programming as a NLP Practitioner.
                    </p>
                    <hr className='disp-line'>
                    </hr>
                    <p className='read-more-disp'>
                        Read more
                    </p>
                </div>
                <div className='card-02'>
                    <FaLightbulb className='dis-icon' />
                    <h4 className='card-head-dis'>
                        Hypnotherapy
                    </h4>
                    <p className='para-disp-inner'>
                        Allows you to join American Board of Hypnotherapy as a Hypnosis Practitioner.
                    </p>
                    <hr className='disp-line'>
                    </hr>
                    <p className='read-more-disp'>
                        Read more
                    </p>
                </div>
                <div className='card-03'>
                    <FaTrophy className='dis-icon' />
                    <h4 className='card-head-dis'>
                        Affiliation
                    </h4>
                    <p className='para-disp-inner'>
                        Provide the tools and strategies to effectively use NLP in your coaching practice.
                    </p>
                    <hr className='disp-line'>
                    </hr>
                    <p className='read-more-disp'>
                        Read more
                    </p>
                </div>
            </div>
            <div className='disclamer-cards'>
                <div className='card-01'>
                    <FaTools className='dis-icon' />
                    <h4 className='card-head-dis'>
                        Tools
                    </h4>
                    <p className='para-disp-inner'>
                        Provide the tools and strategies to effectively use NLP in your coaching practice.
                    </p>
                    <hr className='disp-line'>
                    </hr>
                    <p className='read-more-disp'>
                        Read more
                    </p>
                </div>
                <div className='card-02'>
                    <FaExchangeAlt className='dis-icon' />
                    <h4 className='card-head-dis'>
                        Change
                    </h4>
                    <p className='para-disp-inner'>
                        Enables you to create meaningful change in yourself and the lives of your clients.
                    </p>
                    <hr className='disp-line'>
                    </hr>
                    <p className='read-more-disp'>
                        Read more
                    </p>
                </div>
                <div className='card-03'>
                    <FaStar className='dis-icon' />
                    <h4 className='card-head-dis'>
                        Mastery
                    </h4>
                    <p className='para-disp-inner'>
                        Gives you proven techniques that work and mastering the art of coaching with confidence, clarity, and purpose.
                    </p>
                    <hr className='disp-line'>
                    </hr>
                    <p className='read-more-disp'>
                        Read more
                    </p>
                </div>
            </div>
            <p className='dis-para'>
                If you're serious about transforming lives and growing your coaching business, this course will guide you every step of the way.
            </p>
        </div>
    )
}
