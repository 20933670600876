import React, { useState, useEffect } from 'react';
import './Popup.css';
import { FaTimes, FaEnvelope } from 'react-icons/fa'; 

const NewsletterPopup = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  const handleSubscribe = () => {
    alert(`Subscribed with: ${email}`);
    setIsVisible(false); 
  };

  if (!isVisible) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-container">
        <button className="close-button" onClick={handleClose}>
          <FaTimes />
        </button>
        <h2 className="popup-title">Subscription for Newsletters</h2>
        <div className="email-container">
          <FaEnvelope className="email-icon" />
          <input
            type="email"
            placeholder="Enter Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="email-input"
          />
        </div>
        <button onClick={handleSubscribe} className="subscribe-button">
          Subscribe
        </button>
      </div>
    </div>
  );
};

export default NewsletterPopup;
