import React from 'react';
import './Questions.css';
import './Athourity.css'

export default function Questions() {
    const faqs = [
        {
            question: 'Internationally Recognized Certification',
            answer: 'Gain the credentials that set you apart. Your certification is more than just a qualification—it’s a stamp of approval that shows clients you have the expertise to guide them through life-changing transformations.'
        },
        {
            question: 'Proven, Evidence-Based Techniques',
            answer: 'NLP and Hypnosis are not just trends; they’re methodologies backed by decades of success in coaching, therapy, and business. With these tools, you can confidently offer clients solutions that work, building trust and authority from the start.'
        },
        {
            question: 'Expertise That Inspires Confidence',
            answer: 'When you’re certified in NLP and Hypnosis, clients feel confident that they’re working with someone who truly understands the science of change. Your credibility as a practitioner will attract clients who are ready to invest in real results.'
        },
        {
            question: 'Authority in Client Conversations',
            answer: 'Your knowledge of NLP and Hypnosis will give you the ability to handle client concerns, overcome objections, and provide clear, compelling answers. As you demonstrate your expertise, clients will trust your guidance and see you as a true leader in your field.'
        },
    ];

    return (
        <div className='questions-container' id='questions'>
            <h1 className='head athority'>
                Build Authority and <span className='bold'> Stand Out </span> in a Crowded Market
            </h1>
            <p className='para-athority'>
                In today’s competitive landscape, clients are not just looking for another coach—they’re seeking an expert. Someone with the knowledge, credibility, and proven techniques to deliver real transformation. As a <span className='bold-1'> Certified NLP and Hypnosis Practitioner </span> , you’ll not only master powerful tools for change, but you’ll also position yourself as an <span className='bold-1'> authority </span>  in your field.
            </p>
            <div className='questions'>
                {faqs.map((faq, index) => (
                    <div key={index} className='faq-item'>
                        <h2 className='bold'cla>{index + 1}. {faq.question}</h2>
                        <p className='para-questions'>{faq.answer}</p>
                    </div>
                ))}
            </div>
            <p className='para-athority bold-1'>
                By becoming a Certified NLP and Hypnosis Practitioner, you’ll not only expand your skills—you’ll cement your authority, command higher fees, and build a reputation as a leading expert capable of delivering lasting results.
            </p>
        </div>
    );
}
