import React from 'react'
import './Leadership.css'
import { FaBook, FaCalendarAlt, FaPhone } from 'react-icons/fa'
import { FaClock, FaQuoteLeft, FaLightbulb, FaHandshake } from 'react-icons/fa6'
import Analytics from './Analytics';
import ContactForm1 from './ContactForm1';
import { useInView } from 'react-intersection-observer';
import { useState } from 'react';

export default function
  () {

  const scrollToContactForm = () => {
    const contactFormElement = document.getElementById('contact-form');
    if (contactFormElement) {
      contactFormElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='leadership-course'>
      <div className='contact-header'>
        <p className='header-text'>
          ITL PROVIDES WITH QUALITY SERVICES
        </p>
        <p className='header-sub-text'>
          Limited Seats Only
        </p>
      </div>
      <div className='about-back-1'>
        <h1 className='about-leadership'>
          Leadership
        </h1>
        <p className='about-title-leadership'>
          Driven by passion and excellence.
        </p>
        <div className='book-demo'>
          <FaCalendarAlt className='demo-icon' />
          <p className='demo-text' onClick={scrollToContactForm}>
            Book a Demo
          </p>
        </div>
      </div>
      <div className='course-001'>
        <div className='course-1left course-1left01'>
        </div>
        <div className='course-1next course-next01'>
          <div className='course-next-inner'>
            <h2 className='course-title-1'>
              Leadership Training
            </h2>
            <p className='course-sub-title'>
              Duration. 6 weeks
            </p>
            <p className='course-date course-date-1'>
              2nd October, 2025
            </p>
            <p className='course-location'>
              Lahore, Punjab
            </p>
            <p className='course-disp'>
              Our Leadership Training Courses are crafted to transform potential into performance. Gain insights into effective leadership styles, strategic thinking, and team management. These courses are ideal for current and aspiring leaders looking to drive impactful change within their organizations.
            </p>
            <button className='course-readmore-1'>
              Book a demo
            </button>
          </div>
        </div>
      </div>
      <hr className='course-line-001'>
      </hr>
      <div className='course-overview'>
        <h3 className='overview-head'>
          Course Overview
        </h3>
        <p className='overview-para'>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting
        </p>
      </div>
      <div className='course-content'>
        <h3 className='overview-head'>
          Course Content
        </h3>
        <ul className='content-list'>
          <li>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
          </li>
          <li>
            Totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          </li>
          <li>
            Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.
          </li>
          <li>
            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form.
          </li>
          <li>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium
          </li>
        </ul>
      </div>
      <h3 className='content-highlights'>
        Content Highlights
      </h3>
      <div className='our-offers-01'>
        <div className='offers'>
          <img className='im-offer' src='module-1.jpg' />
          <div>
            <p className='offer-name'>
              Module-1
            </p>
            <p className='offer-line'>
              Developing Leadership Skills for Growth and Success
            </p>
            <div className='offers-duration'>
              <div className='offer-duration-1'>
                <FaBook className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  12 Lessons
                </p>
              </div>
              <div className='offer-duration-2'>
                <FaClock className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  6 Weeks
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='offers'>
          <img className='im-offer' src='module-2.jpg' />
          <div>
            <p className='offer-name'>
              Module-2
            </p>
            <p className='offer-line'>
              Developing Leadership Skills for Growth and Success
            </p>
            <div className='offers-duration'>
              <div className='offer-duration-1'>
                <FaBook className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  12 Lessons
                </p>
              </div>
              <div className='offer-duration-2'>
                <FaClock className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  6 Weeks
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='offers' >
          <img className='im-offer' src='module-3.jpg' />
          <div>
            <p className='offer-name'>
              Module-3
            </p>
            <p className='offer-line'>
              Developing Leadership Skills for Growth and Success
            </p>
            <div className='offers-duration'>
              <div className='offer-duration-1'>
                <FaBook className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  12 Lessons
                </p>
              </div>
              <div className='offer-duration-2'>
                <FaClock className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  6 Weeks
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='offers'>
          <img className='im-offer' src='module-4.jpg' />
          <div>
            <p className='offer-name'>
              Module-4
            </p>
            <p className='offer-line'>
              Developing Leadership Skills for Growth and Success
            </p>
            <div className='offers-duration'>
              <div className='offer-duration-1'>
                <FaBook className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  12 Lessons
                </p>
              </div>
              <div className='offer-duration-2'>
                <FaClock className='icon-offer-1' color='#cc9f25' size={14} />
                <p className='lessons'>
                  6 Weeks
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='course-analytics'>
        <h3 className='analytics-head'>
          Course Analytics
        </h3>
        <p className='analytics-para-1'>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
        </p>
        <Analytics />
      </div>
      <div className='client-testinomials'>
        <h3 className='testinomials-head'>
          Clients' Testinomials
        </h3>
        <p className='analytics-para-1'>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
        </p>
        <div className='testinomial-grid'>
          <div className='testinomial-1'>
            <FaQuoteLeft size={25} className='icon-comma' color='white' />
            <p className='testinomial-para'>
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here
            </p>
          </div>
          <div className='testinomial-1'>
            <FaQuoteLeft size={25} className='icon-comma' color='white' />
            <p className='testinomial-para'>
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here
            </p>
          </div>
          <div className='testinomial-1'>
            <FaQuoteLeft size={25} className='icon-comma' color='white' />
            <p className='testinomial-para'>
              It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here
            </p>
          </div>
        </div>
      </div>
      <ContactForm1 />
    </div>
  )
}
