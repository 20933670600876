import React, { useEffect, useState } from 'react';
import { FaBook, FaRegMoneyBillAlt } from 'react-icons/fa';
import './Enroll.css'; 

const Enroll = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 100) { 
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={`enroll-button ${isVisible ? 'visible' : 'hidden'}`}>
      <button>
        <FaBook  onClick={() => scrollToSection('contact-form')}/> Enroll for just $260
      </button>
    </div>
  );
};

export default Enroll;
