import React from 'react';
import './Questions.css';

export default function Questions() {
    const faqs = [
        {
            question: 'Is NLP and Hypnosis really effective?',
            answer: 'Yes, NLP and Hypnosis are proven, evidence-based methodologies used by coaches, therapists, and consultants worldwide to help people overcome limiting beliefs, change behaviors, and achieve breakthroughs. Many of our graduates have used these techniques to create real, measurable transformations for their clients and themselves. The course includes practical demonstrations and real-world applications to ensure that you can confidently apply these techniques effectively.'
        },
        {
            question: 'Is this certification worth the investment?',
            answer: 'Absolutely. As a Certified NLP and Hypnosis Practitioner, you’ll gain skills that can significantly enhance your coaching or therapeutic practice. This certification allows you to charge premium rates, attract more clients, and differentiate yourself in the marketplace. Many of our graduates have experienced an increase in both their client base and income after completing the program. The return on investment comes from your ability to offer a high-value service that clients are willing to pay for.'
        },
        {
            question: 'I don’t have enough time to take the course.',
            answer: 'We understand that your schedule may be busy, which is why we offer flexible, self-paced learning. You can complete the course at your own pace, with lifetime access to the materials. Whether you have just a few hours a week or more time to dedicate, you’ll be able to work through the course without pressure, and revisit the lessons anytime you need.'
        },
        {
            question: 'What if I can’t apply what I’ve learned?',
            answer: 'This course is designed to be practical and hands-on. You won’t just learn theory—you’ll practice real NLP and Hypnosis techniques through exercises, demonstrations, and case studies. Plus, we provide detailed templates and ongoing business support to help you implement what you’ve learned with clients. By the time you finish the course, you’ll feel confident in your ability to apply these techniques in your coaching practice.'
        },
        {
            question: 'Is this just another ‘woo-woo’ or pseudoscience course?',
            answer: 'No. Both NLP and Hypnosis are grounded in behavioral science and psychology, with decades of research supporting their effectiveness. The techniques you’ll learn in this course have been used by professionals around the world to create lasting change in clients. We focus on evidence-based methods, and you’ll be trained to use these powerful tools in a professional, results-driven way.'
        },
        {
            question: 'I’ve tried other coaching techniques, and they didn’t work for me. How is this different?',
            answer: 'Unlike many surface-level coaching techniques, NLP and Hypnosis work at the subconscious level, where real change happens. You’ll learn how to help clients uncover and rewire limiting beliefs and deep-seated behaviors, leading to more profound and lasting transformations. Our course teaches methods that go beyond traditional coaching, giving you tools to create breakthroughs even where other approaches may have failed.'
        },
        {
            question: 'Will this certification be recognized?',
            answer: 'Yes. Our Certified NLP and Hypnosis Practitioner certification is internationally recognized and respected by professionals across coaching, therapy, and consulting industries. It will give you the credibility needed to attract clients and command higher fees. With this certification, you’ll be able to market yourself as a certified expert in these highly sought-after techniques.'
        },
        {
            question: 'What if I’m not good at hypnosis or NLP?',
            answer: 'No prior experience is needed! This course is designed for both beginners and experienced practitioners. We provide clear, step-by-step guidance to ensure that you fully understand and can apply each technique, regardless of your prior knowledge. You’ll also have access to demonstrations, practice exercises, and expert support to help you build your confidence in using NLP and Hypnosis effectively.'
        },
        {
            question: 'How do I know this course will lead to real results for my clients?',
            answer: 'The techniques we teach are focused on producing measurable results. Many of our graduates have successfully helped their clients achieve significant breakthroughs using NLP and Hypnosis. You’ll learn proven methods that have been refined over years of practice and can be adapted to suit various client needs, whether in personal development, business coaching, or therapy. We also provide you with templates and tools to track client progress and ensure that your sessions lead to meaningful outcomes.'
        },
        {
            question: 'What if I can’t afford the course right now?',
            answer: 'We offer flexible payment plans to make the course more accessible, so you can spread out the cost and begin learning without financial stress. Investing in this certification is an investment in your future. The skills you gain can help you attract more clients, increase your income, and grow your business. Many graduates have recouped their investment quickly by applying what they’ve learned to grow their practice.'
        },
    ];

    return (
        <div className='questions-container' id='questions'>
            <h1 className='head'>
                Frequently Asked <span className='bold'> Questions</span>
            </h1>
            <div className='questions'>
                {faqs.map((faq, index) => (
                    <div key={index} className='faq-item'>
                        <h2 cla>{index + 1}. {faq.question}</h2>
                        <p className='para-questions'>{faq.answer}</p>
                    </div>
                ))}
            </div>

        </div>
    );
}
